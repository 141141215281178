@import 'tailwindcss/base';
@import 'tailwindcss/components';

html {
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
}

.content-wrapper {
    @apply py-6;
    @apply text-gray-800;
    @apply px-1;
}

@screen sm {
    .content-wrapper {
        @apply px-4;
    }
}

/* ***** PAGE CONTAINER ***** */
.page-container-headline {
    @apply px-5;
    @apply py-5;
    @apply text-2xl;
    @apply font-bold;
    @apply text-gray-800;
}

.page-container-headline-event {
    @apply py-5;
    @apply text-2xl;
    @apply font-bold;
    @apply text-gray-800;
}

/* ***** FORM GROUP ***** */

.form-group {
    @apply flex-wrap;
    @apply px-4;
    @apply py-6;
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @apply border-gray-300;
    @apply border-b;
    @apply border-solid;
}

/* ***** LABEL ***** */

.label {
    @apply block;
    @apply font-semibold;
    @apply text-lg;
    @apply pr-6;
    @apply text-gray-800;
}
.label span {
    display: block;
    @apply font-normal;
    @apply text-sm;
}

/* ***** INPUT ***** */

.input {
    @apply flex-1;
    @apply rounded;
    @apply p-4;
    @apply shadow;
}
.input::placeholder {
    @apply text-gray-500;
    @apply opacity-75;
}

.checkbox {
    @apply mr-2;
    @apply h-5;
    @apply w-5;
    @apply text-gray-600;
    @apply rounded;
}

/* ***** CONTACT SEARCH ***** */

.wrapper-contact {
    @apply block;
    @apply px-2;
    @apply items-center;
    @apply justify-start;
    @apply px-2;
    @apply my-4;
}

.btn-contact {
    @apply font-semibold;
    @apply text-xs;
    @apply bg-gray-50;
    @apply border;
    @apply border-gray-300;
    @apply py-1;
    @apply px-2;
    @apply rounded;
    @apply shadow-sm;
    @apply text-gray-700;
    @apply leading-4;
    @apply mr-4;
}
.btn-contact:hover {
    @apply bg-gray-200;
}
.btn-contact:focus {
    @apply outline-none;
}

.inputField-contact {
    @apply w-20;
    @apply py-1;
    @apply px-1;
    @apply border;
    @apply rounded;
    @apply border-gray-300;
    @apply text-sm;
    @apply leading-4;
    @apply text-gray-700;
}

.inputField-contact:hover {
    @apply bg-gray-50;
}
.inputField-contact:focus {
    @apply outline-none;
}

.inputField-contactEmail {
    @apply w-36;
}

.name-contact {
    @apply h-12;
    @apply mr-4;
}

@screen sm {
    .wrapper-contact {
        @apply flex;
        @apply px-8;
        @apply justify-center;
    }
    .name-contact {
        @apply mr-6;
    }

    .inputField-contactName {
        @apply w-28;
    }
}

@screen md {
    .inputField-contactEmail {
        @apply w-44;
    }
    .inputField-contactName {
        @apply w-40;
    }
}

@screen lg {
    .inputField-contactEmail {
        @apply w-52;
    }
    .inputField-contactName {
        @apply w-48;
    }
}

@import 'tailwindcss/utilities';
